<template>
  <SettingsCard
    v-if="user.notificationSettings"
    :title="$t('settings.notification.title')"
    :subtitle="$t('settings.notification.subtitle')"
    icon="mdi-bell-ring"
    :is-default-open="false"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.notification.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.notification.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-form
            ref="phoneForm"
            v-model="isFormValid"
            name="update-user-phone"
            @submit.prevent="submit()"
            @change="error = ''"
          >
            <div class="justify-center d-flex mb-sm-0">
              <PhoneField
                v-model="notificationPhone"
                :disabled="!hasMobileNotifications"
                :loading="isLoading.phone"
                update-on-input
              />
            </div>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.notification.body-texts.2') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.notification.body-texts.2') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-end"
          :class="{'justify-center px-0': $vuetify.breakpoint.smAndDown}"
        >
          <div class="w-full">
            <v-row>
              <v-col
                cols="12"
                sm="8"
              />
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <div class="d-flex flex-column align-center">
                  <div class="subtitle-2">
                    {{ $t('settings.notification.options.0') }}
                  </div>
                  <v-icon>
                    mdi-email-outline
                  </v-icon>
                </div>
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <div class="d-flex flex-column align-center">
                  <div class="subtitle-2">
                    {{ $t('settings.notification.options.1') }}
                  </div>
                  <v-icon>
                    mdi-phone-message
                  </v-icon>
                </div>
              </v-col>
            </v-row>

            <Feature :feature-slug="featureNames.LEAD">
              <div>
                <!-- New Contact -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    {{ $t('settings.notification.option-new') }}
                    <v-tooltip
                      v-if="!user.notificationSettings.newConversion.email && !user.notificationSettings.newConversion.sms"
                      top
                      color="warning"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="warning"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ $t('settings.notification.no-notifications') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.newConversion.email"
                      :loading="isLoading.newConversion.email"
                      @change="saveSettings('newConversion', 'email')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.newConversion.sms"
                      :disabled="!hasValidPhone || !hasMobileNotifications"
                      :loading="isLoading.newConversion.sms"
                      @change="saveSettings('newConversion', 'sms')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    {{ $t('settings.notification.option-due-date-reminder') }}
                    <v-tooltip
                      v-if="!user.notificationSettings.dueDate.email && !user.notificationSettings.dueDate.sms"
                      top
                      color="warning"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="warning"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ $t('settings.notification.no-notifications') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.dueDate.email"
                      :loading="isLoading.dueDate.email"
                      @change="saveSettings('dueDate', 'email')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.dueDate.sms"
                      :disabled="!hasValidPhone || !hasMobileNotifications"
                      :loading="isLoading.dueDate.sms"
                      @change="saveSettings('dueDate', 'sms')"
                    />
                  </v-col>
                </v-row>

                <!-- Contact assigned -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    {{ $t('settings.notification.option-assigned') }}
                    <v-tooltip
                      v-if="!user.notificationSettings.leadAssigned.email && !user.notificationSettings.leadAssigned.sms"
                      top
                      color="warning"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="warning"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ $t('settings.notification.no-notifications') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.leadAssigned.email"
                      :loading="isLoading.leadAssigned.email"
                      @change="saveSettings('leadAssigned', 'email')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    class="justify-center d-flex"
                  >
                    <v-switch
                      v-model="user.notificationSettings.leadAssigned.sms"
                      :disabled="!hasValidPhone || !hasMobileNotifications"
                      :loading="isLoading.leadAssigned.sms"
                      @change="saveSettings('leadAssigned', 'sms')"
                    />
                  </v-col>
                </v-row>
              </div>
            </Feature>

            <!-- Reminder E-Mail-->
            <Feature
              :feature-slug="featureNames.TODO"
            >
              <v-row>
                <v-col
                  sm="8"
                  cols="12"
                  class="d-flex"
                >
                  <div>
                    {{ $t('settings.notification.option-update') }}

                    <div class="grey--text caption mt-n1">
                      {{ $t('settings.notification.option-update-subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="justify-center d-flex"
                >
                  <v-switch
                    v-model="user.notificationSettings.reminder.email"
                    :loading="isLoading.reminder.email"
                    @change="saveSettings('reminder', 'email')"
                  />
                </v-col>
              </v-row>
            </Feature>

            <Feature
              v-slot="{feature}"
              :feature-slug="featureNames.CONTENT_CREATOR"
            >
              <v-row v-if="feature.config.schedulePost && feature.config.workdaysBeforeContentDepletionNotification.length > 0">
                <v-col
                  sm="8"
                  cols="12"
                  class="d-flex"
                >
                  <div>
                    {{ $t('settings.notification.content-depletion') }}

                    <div class="grey--text caption mt-n1">
                      {{ $t('settings.notification.content-depletion-subtitle',
                            { days: feature.config.workdaysBeforeContentDepletionNotification.join(", ").replace(/,(?=[^,]*$)/, ' ' + $t('labels.and')) }) }}
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="justify-center d-flex"
                >
                  <v-switch
                    v-model="user.notificationSettings.contentDepletion.email"
                    :loading="isLoading.contentDepletion.email"
                    @change="saveSettings('contentDepletion', 'email')"
                  />
                </v-col>
              </v-row>
            </Feature>

            <Feature
              v-slot="{feature}"
              :feature-slug="featureNames.CONTENT_CREATOR"
            >
              <v-row v-if="feature.config.schedulePost">
                <v-col
                  sm="8"
                  cols="12"
                  class="d-flex"
                >
                  <div>
                    {{ $t('settings.notification.post-reminder') }}

                    <div class="grey--text caption mt-n1">
                      {{ $t('settings.notification.post-reminder-subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="justify-center d-flex"
                >
                  <v-switch
                    v-model="user.notificationSettings.socialMediaPostReminder.email"
                    :loading="isLoading.socialMediaPostReminder.email"
                    @change="saveSettings('socialMediaPostReminder', 'email')"
                  />
                </v-col>
              </v-row>
            </Feature>

            <!-- General -->
            <Feature
              v-slot="{feature}"
              :feature-slug="featureNames.NOTIFICATIONS"
            >
              <v-row v-if="feature.config.hasGeneralNotifications">
                <v-col
                  sm="8"
                  cols="12"
                  class="d-flex"
                >
                  <div>
                    {{ $t('settings.notification.option-overall') }}

                    <div class="grey--text caption mt-n1">
                      {{ $t('settings.notification.option-overall-subtitle', { readablePlatformName }) }}
                    </div>
                  </div>
                  <v-tooltip
                    v-if="!user.notificationSettings.news.email"
                    top
                    color="warning"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="warning"
                        class="pl-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>{{ $t('settings.notification.no-notifications') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="justify-center d-flex"
                >
                  <v-switch
                    v-model="user.notificationSettings.news.email"
                    :loading="isLoading.news.email"
                    @change="saveSettings('news', 'email')"
                  />
                </v-col>
              </v-row>
            </Feature>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-alert
      icon="mdi-lightbulb-on-20"
      prominent
      text
      type="info"
      class="ma-0 mt-4 mb-6"
    >
      <p class="ma-0 font-weight-bold">
        {{ $t('labels.hint') }}:
      </p>
      <ul
        v-if="hasMobileNotifications"
        style="list-style: disc;"
      >
        <li>{{ $t('alerts.settings.notification.info') }}</li>
        <li>{{ $t('alerts.settings.notification.info-platform', { readablePlatformName }) }}</li>
      </ul>
      <div
        v-else
        class="d-flex flex-md-row flex-column justify-space-between align-center"
      >
        <span
          :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown, 'ml-4':$vuetify.breakpoint.mdAndUp}"
        >
          {{ $t('alerts.settings.notification.info-product') }}
        </span>

        <Permission
          v-if="showUpgradeButton"
          permission="subscription:change"
        >
          <Feature :feature-slug="featureNames.CHANGE_SUBSCRIPTION">
            <v-btn
              color="info"
              :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown, 'mr-4':$vuetify.breakpoint.mdAndUp}"
              @click="isUpgradeDialogOpen = true"
            >
              {{ $t('buttons.upgrade-now.cta') }}
            </v-btn>
          </Feature>
        </Permission>
      </div>
    </v-alert>
    <ChangeSubscription
      :dialog="isUpgradeDialogOpen"
      current-package="LIGHT"
      :company="company"
      :hide-downgrade="true"
      @close="isUpgradeDialogOpen = false"
    />
  </SettingsCard>
</template>

<script>
import UPDATE_USER_NOTIFICATION_SETTINGS from './queries/UpdateUserNotificationSettings.gql'
import UPDATE_USER_PHONE from './queries/UpdateUserPhone.gql'
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '@/components/SettingsCard.vue'
import PhoneField from '@/components/PhoneField.vue'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import ChangeSubscription from '../accountSettings/ChangeSubscription'

export default {
  components: { SettingsCard, PhoneField, ChangeSubscription, Permission },
  mixins: [brandingMixin, featureMixin],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    refetchUser: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      notificationPhone: this.user.phone,
      isFormValid: false,
      spinnerTimeout: null,
      debounceTimeout: null,
      isUpgradeDialogOpen: false,
      isLoading: {
        leadAssigned: {
          sms: false,
          mail: false
        },
        newConversion: {
          sms: false,
          mail: false
        },
        news: {
          mail: false
        },
        reminder: {
          mail: false
        },
        dueDate: {
          sms: false
        },
        contentDepletion: {
          mail: false
        },
        socialMediaPostReminder: {
          mail: false
        },
        phone: false
      }
    }
  },
  computed: {
    isPhoneDirty () {
      return this.notificationPhone && this.user.phone !== this.notificationPhone
    },
    hasValidPhone () {
      return this.user.phone && this.user.phone.length > 0
    },
    hasMobileNotifications () {
      return this.getFeature(this.featureNames.NOTIFICATIONS).config.hasMobileNotifications
    },
    showUpgradeButton () {
      return !this.isPlatformMarkero
    }
  },

  watch: {
    notificationPhone () {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading.phone = true
        this.spinnerTimeout = setTimeout(() => {
          this.submit()
        }, 1000)
      }, 2000)
    }
  },
  methods: {
    async saveSettings (notification, source) {
      this.isLoading[notification][source] = true
      const enabled = this.user.notificationSettings[notification][source]

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_NOTIFICATION_SETTINGS,
          variables: {
            input: { notification, source, enabled }
          }
        })

        this.spinnerTimeout = setTimeout(() => {
          this.refetchUser()
          this.isLoading[notification][source] = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.notification.success') })
        }, 1000)
      } catch (e) {
        this.spinnerTimeout = setTimeout(() => {
          this.refetchUser()
          this.isLoading[notification][source] = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.notification.error') })
        }, 1000)
      }
    },

    async submit () {
      if (this.$refs.phoneForm.validate()) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_USER_PHONE,
            variables: {
              input: { phone: this.notificationPhone }
            }
          })
          this.isLoading.phone = false
          this.refetchUser()
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.notification.success-mobile') })
        } catch (e) {
          if (e.graphQLErrors?.[0]?.message === 'INVALID_PHONE_ERROR') {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.notification.error-mobile') })
          } else {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.notification.error') })
          }
          this.refetchUser()
          this.isLoading.phone = false
        }
      }
    }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  }
}
</script>

<style scoped>

.v-input--selection-controls {
  margin-top: 0;
  max-height: 30px;
}
</style>
